/* eslint-disable react/prop-types */
// importSource //

import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import tw, { css } from "twin.macro";
import { Grid, StripePaymentCapture } from "~components";
import { useAuth, useTheme } from "~hooks";
import { Logomark } from "~components/_common/Logos/index.jsx";

import logo from "~assets/icons/huge-logo.svg";

const StripeSlide = ({
  activeStep,
  onComplete,
  accountId,
  planId,
  trial,
  withTrial
}) => {
  // ==========================================================================
  // context / ref / state

  const { subscribe, confirmSubscribe } = useAuth();
  const stripe = useStripe();
  const { colours, colourTransitionCSS } = useTheme();

  const [subscribing, setSubscribing] = useState(false);

  // ==========================================================================
  // render

  return (
    <div
      css={[
        css`
          transition: 0.3s ease transform;

          transform: translate3d(-${activeStep * 100}%, 0, 0);

          flex: 0 0 auto;
        `,
        tw`w-screen h-full relative`
      ]}
    >
      {subscribing && (
        <div
          css={[
            css`
              //
            `,
            tw`w-screen h-full fixed top-0 right-0 bottom-0 left-0 z-30`
          ]}
        >
          <div
            css={[
              css`
                ${colourTransitionCSS};

                background: ${colours.background};
              `,
              tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
            ]}
          >
            <img
              css={[tw`w-full h-full relative block object-cover`]}
              src={logo}
              alt="Huge logo"
            />
          </div>

          <Grid
            _css={[
              css`
                //
              `,
              tw`h-full items-center z-10`
            ]}
          >
            <div
              css={[
                css`
                  @keyframes appear {
                    0% {
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;
                    }
                    100% {
                      transform: translate3d(0, 0, 0);
                      opacity: 1;
                    }
                  }

                  grid-column: span 6 / span 6;
                  grid-column-start: 4;
                  color: ${colours.foreground};
                `,
                tw`relative rounded-xl text-wheat text-center`
              ]}
            >
              <h3
                css={[
                  css`
                    animation: 1s appear ease forwards;
                    transform: translate3d(0, 1rem, 0);
                    opacity: 0;
                  `,
                  tw`font-head text-h2-md`
                ]}
              >
                Almost there...
              </h3>

              <p
                css={[
                  css`
                    animation: 1s appear ease forwards;
                    transform: translate3d(0, 1rem, 0);
                    opacity: 0;
                    animation-delay: 150ms;
                  `,
                  tw`mt-4 font-body text-b1-md`
                ]}
              >
                Linking accounts and cleaning up...
              </p>

              <div
                css={[
                  css`
                    @keyframes load-fader {
                      0% {
                        opacity: 1;
                      }
                      50% {
                        opacity: 0;
                      }
                      100% {
                        opacity: 1;
                      }
                    }
                  `,
                  tw`relative flex items-center justify-center`
                ]}
              >
                <Logomark
                  _css={[
                    css`
                      animation: 5s load-fader ease infinite;
                    `,
                    tw`w-12 relative block mt-6`
                  ]}
                  color={colours.foreground}
                />
              </div>
            </div>
          </Grid>
        </div>
      )}

      <div
        css={[
          css`
            ${colourTransitionCSS};

            background: ${colours.background};
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
        ]}
      >
        <img
          css={[tw`w-full h-full relative block object-cover`]}
          src={logo}
          alt="Huge logo"
        />
      </div>

      <Grid _css={[tw`h-full items-center`]}>
        <div
          css={[
            css`
              ${colourTransitionCSS};

              background: ${colours.foreground};
              color: ${colours.background};
              text-align: center;
            `,
            tw`col-span-6 col-start-4 relative z-10 overflow-hidden rounded-xl p-6`
          ]}
        >
          <h2
            css={[
              css`
                transform: translate3d(0, 1rem, 0);
                opacity: 0;
                animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1) appear-up
                  forwards;
              `,
              tw`mt-4 font-head text-h3-md`
            ]}
          >
            Welcome to The WIP!
          </h2>

          <p
            css={[
              css`
                transform: translate3d(0, 1rem, 0);
                opacity: 0;
                animation: 1s cubic-bezier(0.215, 0.61, 0.355, 1) appear-up
                  forwards;
                animation-delay: 0.25s;
              `,
              tw`mt-4 mb-8 font-body text-b2-md`
            ]}
          >
            Please enter your card settings below for use with tokens and
            recurring plans.
          </p>

          <StripePaymentCapture
            accountId={accountId}
            onComplete={(paymentMethodId) => {
              setSubscribing(true);

              subscribe({
                accountId,
                onComplete: async ({ data }) => {
                  const { actionRequired, clientSecret } = data;

                  setSubscribing(false);

                  if (actionRequired) {
                    const { paymentIntent, error } =
                      await stripe.confirmCardPayment(clientSecret);

                    if (error) {
                      console.error(error);
                      return;
                    }
                  }

                  if (typeof onComplete === `function`) {
                    onComplete();
                  }

                  confirmSubscribe({
                    paymentMethodId,
                    subscription: data
                  });
                },
                paymentMethodId,
                trial
              });
            }}
          />
        </div>
      </Grid>
    </div>
  );
};

export default StripeSlide;
